.App {
  text-align: center;
  background-color: #ffffff; /* Added a white background for the whole app */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #f7f7f7; /* Light grey background for header */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333; /* Dark text color against light background */
}

.App-link {
  color: #007bff; /* Using a shade of blue for links, but you can choose any color */
  text-decoration: none;
}

.App-link:hover {
  color: #0056b3; /* Darker shade for hover effect */
}
